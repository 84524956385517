import React from "react";
import { IconButton } from "rsuite";
import { getAppHealthIcon } from "../utils";

const Argocd = ({...props}) => {
	if (props.name === "core-backend") {
        return (
            <div style={{ paddingLeft: "6px", paddingTop: "3px" }}>
                {getAppHealthIcon(props.value.argocd.status)}
            </div>
        )
    } else {
        return (
            <>
                <IconButton
					icon={getAppHealthIcon(props.value.argocd.status)}
					circle
					size="xs"
                    href={`https://argocd.betcloud.io/applications/argocd/${ props.value.argocd.name }`}
                    target="_blank"
                    appearance="link"
                    disabled={props.readOnly}
                />
            </>
        )
    }
};

export default Argocd;