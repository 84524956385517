import React, { useEffect, useState } from 'react';
import { Form, Button, Divider, Content, Footer } from "rsuite";
import { toast } from 'react-toastify';
import FormGroup from '../FormGroup';
import FormResponse from '../FormResponse';
import { playFetch } from '../../lib/playfetch';
import { envType, formGroupType } from '../FormGroup/config';
import { toTitleCase } from '../../lib/utils';

const Insert = ({ props }) => {
    const [envName, setEnvName] = useState([]);
    const [insertData, setInsertData] = useState([]);
    const [loadResult, setLoadResult] = useState(false);
    const [result, setResult] = useState([]);
    const [formError, setFormError] = useState({});
    const [submitDisabled, setSubmitDisabled] = useState(true)

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const insertDataChangeHandler = (value, name) => {
        const index = parseInt(name.split('-')[0], 10)
        const newInsertData = Array.from(insertData);
        newInsertData[index] = value;
        setInsertData(newInsertData);
        if ((props.insertData[index].type.startsWith(formGroupType.FEEDSTEP)) &&
            (props.showEnv === envType.FEEDER)) {
                const newEnvs = JSON.parse(value).environments.map((item, index) => { return item.name })
                setEnvName(newEnvs)
        }
    };

    const handleFormCheck = (formError) => {
        setFormError(formError);
    };

    const callApi = (envIndex) => {
        const formData = new FormData();
        for(let i = 0; i < props.insertData.length; i++) {
            let value = insertData[i]
            if((props.insertData[i].type === formGroupType.BOOLEAN) &&
                    (value === undefined)) {
                value = false;
            }
            if(props.insertData[i].type.startsWith(formGroupType.UPLOADFILE + "/")) {
                formData.append(props.insertData[i].value, value.blobFile, value.name)
            } else {
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        formData.append(props.insertData[i].value, item)
                    })
                } else if ((props.insertData[i].type.startsWith(formGroupType.FEEDSTEP)) &&
                            (props.showEnv === envType.FEEDER)) {
                    formData.append(props.insertData[i].value, JSON.stringify(JSON.parse(value).environments[envIndex]))
                } else {
                    if (value !== undefined) {
                        formData.append(props.insertData[i].value, value)
                    }
                }
            }
        }

        if (props.postEnv === true) {
            for (let i = 0; i < envName.length; i++) {
                formData.append("environments", envName[i])
            }
        }

        const suffixUrl = ((props.showEnv !== envType.NONE) && (props.postEnv !== true)) ? `/${envName[envIndex]}` : ''
        const apiUrl = props.apiUrl + suffixUrl;
        playFetch(apiUrl, "POST", formData)
            .then(data => {
                if(props.postEnv === true) {
                    if (data.status >= 400) {
                        toast.error(data.reason);
                    } else {
                        const msg = (<>
                                <p>
                                    <b>{data.msg}</b>
                                </p>
                            </>);

                        toast.success(msg);
                    }
                    setLoadResult(false)
                } else {
                    /* convert data to array */
                    let data_array = []
                    if(Array.isArray(data)) {
                        data_array = data
                    } else {
                        data_array.push(data)
                    }

                    let newResults = result;
                    if(props.showEnv !== envType.NONE) {
                        newResults[envIndex] = data_array
                    } else {
                        newResults[0] = data_array;
                    }
                    setResult(newResults);
                    if (newResults.includes(undefined) === false) {
                        setLoadResult(false)
                    }
                }
            });
    }
    
    const insertHandler = (event) => {
        event.preventDefault();
        setResult(Array(envName.length).fill(undefined))
        setLoadResult(true);
        if((props.showEnv === envType.NONE) ||
            (props.postEnv === true)) {
            callApi(null)
        } else {
            envName.map((item, index) => callApi(index))
        }
    };

    useEffect(() => {
        const newInsertData = [];
        for (let i = 0; i < props.insertData.length; i++) {
            newInsertData[i] = props.insertData[i].default;
        }
        setInsertData(newInsertData)
        setEnvName([])
        setResult([])
        setLoadResult(false)
    }, [props.id, props.superId, props.insertData]);

    useEffect(() => {
        if (((envName.length > 0) || (props.showEnv === envType.NONE)) &&
            (insertData.length > 0)) {
                setSubmitDisabled(false)
        } else {
            setSubmitDisabled(true)
        }
        setResult([])
        setLoadResult(false)
    }, [envName, insertData, props.showEnv]);

    const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled) || (result.length !== 0));

    return (
        <>
            <Content>
                { (props.feedStep === true) ? <React.Fragment /> : <Divider /> }
                <Form onCheck={handleFormCheck} >
                    {((props.showEnv !== envType.NONE) && (props.showEnv !== envType.FEEDER)) && <FormGroup
                        name="envName"
                        value={envName}
                        multiPick={!props.feedStep}
                        envType={props.showEnv}
                        exclude={props.excludedEnv}
                        label="Environment"
                        type={formGroupType.ENVPICKER}
                        onChange={envNameChangeHandler} />}
                    {props.insertData.map((item, index) => <FormGroup
                                                                key={index.toString() + "-insert"}
                                                                name={index.toString() + "-insert"}
                                                                playbookId={props.playbookId}
                                                                value={(insertData[index] === undefined)? "" : insertData[index]}
                                                                label={item.label}
                                                                type={item.type}
                                                                required={item.required}
                                                                multiPick={item.multiPick}
                                                                onChange={insertDataChangeHandler} />)}
                    <Button
                        appearance="primary"
                        disabled={isSubmitDisabled}
                        onClick={insertHandler}
                        loading={loadResult}>
                            {toTitleCase(props.id)}
                    </Button>
                </Form>
                <Divider />
            </Content>
            <Footer >
                {((result.length !== 0) &&
                    (loadResult === false) &&
                    (props.postEnv !== true)) && <FormResponse
                                                    name={envName}
                                                    response={result}
                                                    feedStep={props.feedStep}
                                                    feeder={props.feeder} /> }
            </Footer>
        </>
    );
};

export default Insert;
