import React, { useEffect, useState } from 'react';
import { Form, Button, Divider, Content, Footer } from "rsuite";
import FormGroup from '../FormGroup';
import FormResponse from '../FormResponse';
import { playFetch } from '../../lib/playfetch';
import { envType, formGroupType } from '../FormGroup/config';
import { toTitleCase } from '../../lib/utils';

const Update = ({ props }) => {
    const [envName, setEnvName] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [whereData, setWhereData] = useState([]);
    const [loadResult, setLoadResult] = useState(false);
    const [result, setResult] = useState([]);
    const [formError, setFormError] = useState({});
    const [submitDisabled, setSubmitDisabled] = useState(true)

    const envNameChangeHandler = (value) => {
        setEnvName(value);
    };

    const updateDataChangeHandler = (value, name) => {
        const index = parseInt(name.split('-')[0], 10)
        const newUpdateData = Array.from(updateData);
        newUpdateData[index] = value;
        setUpdateData(newUpdateData);
        if ((props.updateData[index].type.startsWith(formGroupType.FEEDSTEP)) &&
            (props.showEnv === envType.FEEDER)) {
                const newEnvs = JSON.parse(value).environments.map((item, index) => { return item.name })
                setEnvName(newEnvs)
        }
    };

    const whereDataChangeHandler = (value, name) => {
        const index = parseInt(name.split('-')[0], 10)
        const newWhereData = Array.from(whereData);
        newWhereData[index] = value;
        setWhereData(newWhereData);
    };

    const handleFormCheck = (formError) => {
        setFormError(formError);
    };

    const callApi = (envIndex) => {
        const formData = new FormData();

        /* Need to append dummy data to accomodate all optional parameters.
         * Without this empty body will be posted which is invalid POST request
         */
        formData.append("dummy", "data")
        for(let i = 0; i < props.updateData.length; i++) {
            let value = updateData[i]
            if((props.updateData[i].type === formGroupType.BOOLEAN) &&
                    (value === undefined)) {
                value = false;
            }
            if(props.updateData[i].type.startsWith(formGroupType.UPLOADFILE + "/")) {
                formData.append(props.updateData[i].value, value.blobFile, value.name)
            } else {
                if (Array.isArray(value)) {
                    value.forEach((item, index) => {
                        formData.append(props.updateData[i].value, item)
                    })
                } else if ((props.updateData[i].type.startsWith(formGroupType.FEEDSTEP)) &&
                            (props.showEnv === envType.FEEDER)) {
                    formData.append(props.updateData[i].value, JSON.stringify(JSON.parse(value).environments[envIndex]))
                } else {
                    if (value !== undefined) {
                        formData.append(props.updateData[i].value, value)
                    }
                }
            }
        }

        const suffixUrl = (props.showEnv !== envType.NONE) ? `/${envName[envIndex]}` : ''
        let apiUrl = props.apiUrl + suffixUrl;

        for(let i = 0; i < props.whereData.length; i++) {
            apiUrl = apiUrl + '/' + whereData[i]
        }

        playFetch(apiUrl, "POST", formData)
            .then(data => {
                /* convert data to array */
                let data_array = []
                if(Array.isArray(data)) {
                    data_array = data
                } else {
                    data_array.push(data)
                }

                let newResults = result;
                if(props.showEnv !== envType.NONE) {
                    newResults[envIndex] = data_array;
                } else {
                    newResults[0] = data_array;
                }
                setResult(newResults);
                if (newResults.includes(undefined) === false) {
                    setLoadResult(false)
                }
            });
    }
    
    const updateHandler = (event) => {
        event.preventDefault();
        setResult(Array(envName.length).fill(undefined))
        setLoadResult(true);
        if(props.showEnv === envType.NONE) {
            callApi(null)
        } else {
            envName.map((item, index) => callApi(index))
        }
    };

    useEffect(() => {
        setEnvName([])
        setUpdateData([])
        setWhereData([])
        setResult([])
        setLoadResult(false)
        setSubmitDisabled(true)
    }, [props.id, props.superId]);

    useEffect(() => {
        if (((envName.length > 0) || (props.showEnv === envType.NONE)) &&
            (whereData.length > 0)) {
                setSubmitDisabled(false)
        }
        setResult([])
        setLoadResult(false)
    }, [envName, updateData, whereData, props.showEnv]);

    const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled) || (result.length !== 0));

    return (
        <>
            <Content>
                { (props.feedStep === true) ? <React.Fragment /> : <Divider /> }
                <Form onCheck={handleFormCheck} >
                    {((props.showEnv !== envType.NONE) && (props.showEnv !== envType.FEEDER)) && <FormGroup
                        name="envName"
                        value={envName}
                        multiPick={!props.feedStep}
                        envType={props.showEnv}
                        exclude={props.excludedEnv}
                        label="Environment"
                        type={formGroupType.ENVPICKER}
                        onChange={envNameChangeHandler} />}
                    {props.updateData.map((item, index) => <FormGroup
                                                                key={index.toString() + "-update"}
                                                                name={index.toString() + "-update"}
                                                                playbookId={props.playbookId}
                                                                value={(updateData[index] === undefined)? "" : updateData[index]}
                                                                label={item.label}
                                                                type={item.type}
                                                                required={item.required}
                                                                multiPick={item.multiPick}
                                                                onChange={updateDataChangeHandler} />)}
                    {props.whereData.map((item, index) => <FormGroup
                                                                key={index.toString() + "-where"}
                                                                name={index.toString() + "-where"}
                                                                playbookId={props.playbookId}
                                                                value={(whereData[index] === undefined)? "" : whereData[index]}
                                                                label={item.label}
                                                                type={item.type}
                                                                onChange={whereDataChangeHandler} />)}
                    <Button
                        appearance="primary"
                        disabled={isSubmitDisabled}
                        onClick={updateHandler}
                        loading={loadResult}>
                            {toTitleCase(props.id)}
                        </Button>
                </Form>
                <Divider />
            </Content>
            <Footer >
                {((result.length !== 0) && (loadResult === false)) && <FormResponse
                                                                            name={envName}
                                                                            response={result}
                                                                            feedStep={props.feedStep}
                                                                            feeder={props.feeder} /> }
            </Footer>
        </>
    );
};

export default Update;
