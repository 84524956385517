import React from "react";
import { Whisper, Popover, IconButton } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import Edit from "./Edit";

const Sha = ({...props}) => {
	const editRef = React.useRef();

	 // Ensure the 'updated_at' value exists and is a valid date
	 const updatedAt = new Date(props.value.last_updated_at);

	 // Format the date to local time using 'toLocaleString'
	 const formattedDate = isNaN(updatedAt.getTime())
		 ? "Invalid Date"  // If it's an invalid date, show this message
		 : updatedAt.toLocaleString('en-AU', {
			 timeZoneName: 'short',  // Include the time zone abbreviation (e.g., "AEST")
			 year: 'numeric',
			 month: '2-digit',
			 day: '2-digit',
			 hour: '2-digit',
			 minute: '2-digit',
			 second: '2-digit',
			 hour12: false  // Use 24-hour format
		 });
	
	console.log("updatedAt (Date Object):", updatedAt);
	console.log("formattedDate:", formattedDate);

	const shaElement = <p>{props.value.sha}</p>

	return (
		<div style={{ display: "flex" }} >
			{true &&
			<Whisper
				enterable
				trigger="hover"
				placement="topEnd"
				speaker={<Popover
					visible={true}
					style={{
						margin: "1px",
						padding: "5px",
						borderRadius: "20px",
						border: "5px solid gray",
						backgroundColor: "#f5f4f2"
					}}
				>
					<div style={{ fontStyle: "italic", fontWeight: 'bolder' }}>
					{`Last deployed on ${formattedDate}`}
					</div>
				</Popover>
				}
			>
				<div style={{ flex: 8, justifyContent: "left" }}>
					{shaElement}
				</div>
				</Whisper>}
			
			<div style={{ flex: 1 }}>
				<Whisper
					trigger="none"
					placement="autoHorizontal"
					ref={editRef}
					speaker={<Popover
								style={{
									margin: "1px",
									padding: "5px",
									borderRadius: "20px",
									border: "5px solid gray",
									backgroundColor: "#f5f4f2" }}
							 >
								<Edit
									group={props.group}
									env={props.env}
									name={props.name}
									value={props.value}
									onClose={() => editRef.current.close()} />
							 </Popover>
							}
				>
					<IconButton
						icon={<EditIcon />}
						circle
						size="xs"
						disabled={props.readOnly}
						onClick={() => editRef.current.open()} />
				</Whisper>
			</div>
		</div>
	);
};

export default Sha;
