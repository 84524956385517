import React, { useEffect, useState } from "react";
import { Divider, FlexboxGrid, ButtonToolbar, IconButton, Button, Whisper, Stack } from 'rsuite';
import { toast } from 'react-toastify';
import ReloadIcon from '@rsuite/icons/Reload';
import ArrowDownIcon from '@rsuite/icons/ArrowDown';
import SpinnerIcon from '@rsuite/icons/legacy/Spinner';
import Group from "./group";
import GitopsMenu from "./menu";
import ArgocdMenu from "./Argocd/menu";
import AppDrawer from "./Argocd/appdrawer";
import Filter from "./filter";
import { playFetch } from "../../../lib/playfetch";
import { gitGroups } from "./config"
import { isEmpty } from "lodash";

const Core = ({...props}) => {
	const argocdRef = React.useRef();
	const gitopsRef = React.useRef();
	const [openRefresh, setOpenRefresh] = useState(false)
    const [openScale, setOpenScale] = useState(false)
	const [gitopsData, setGitopsData] = useState({});
    const [filteredData, setFilteredData] = useState({});
	const [loading, setLoading] = useState(true);
    const [compareEnv, setCompareEnv] = useState(false);

	const reloadHandler = (event) => {
		event.preventDefault();
		fetchData()
	};
		
	const fetchData = () => {
		setLoading(true)
		playFetch(`deployments/core/fetch`)
				.then(data => {
					setGitopsData(data)
					setLoading(false)
				});
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleArgocdMenu = (eventKey, event) => {
		argocdRef.current.close()
		if (eventKey === "argocd_refresh_apps") {
			setOpenRefresh(true)
		} else if (eventKey === "argocd_scale_apps") {
			setOpenScale(true)
		}
	}

	const handleGitopsMenu = (eventKey, event) => {
		gitopsRef.current.close()
		if (eventKey === "approvals") {
			window.open(
				"https://bitbucket.org/betcloud/gitops-env-config-management/pull-requests/?state=OPEN&author=%7B0426027e-6976-4a49-8494-6763679aebcc%7D",
				"_blank")
		}
		else if (eventKey === "manual_refresh") {
			setLoading(true)
			playFetch(`deployments/core/manual/pull`)
				.then(data => {
					if (data.status === 200) {
						toast.success(data.msg);
					} else {
                        toast.error(data.msg)
					}
					fetchData()
				});
            
        }
	}

    if (loading === true) {
        return (
            <div style={{ display: "grid", placeItems: "center" }}>
                <SpinnerIcon pulse style={{ fontSize: '5em', margin: "50px" }} />
            </div>
        );
    } else {
	    return (<>
			<FlexboxGrid justify="end">
				<FlexboxGrid.Item colspan={24}>
					<div style={{ display: "flex", position: "sticky", top: "200px", zIndex:995,
								  alignItems: "center",
								  justifyContent: "right" }}>
						<ButtonToolbar>
							<Whisper
								placement="bottomEnd"
								ref={argocdRef}
								trigger="click"
								speaker={<ArgocdMenu onSelect={handleArgocdMenu} />}
							>
								<Button
									color="cyan"
									size="md"
									appearance="primary"
									disabled={loading || props.readOnly}
									style={{ borderRadius: "20px" }}>
										<div style={{ color: "#4C4E52" }} >
											ArgoCD&nbsp;<ArrowDownIcon size="5em" />
										</div>
								</Button>
							</Whisper>
							<Whisper
								placement="bottomEnd"
								ref={gitopsRef}
								trigger="click"
								speaker={<GitopsMenu onSelect={handleGitopsMenu} />}
							>
								<Button
									color="cyan"
									size="md"
									appearance="primary"
									disabled={loading || props.readOnly}
									style={{ borderRadius: "20px" }}>
										<div style={{ color: "#4C4E52" }} >
											GitOps&nbsp;<ArrowDownIcon size="5em" />
										</div>
								</Button>
							</Whisper>
							<IconButton
								icon={<ReloadIcon color="#4C4E52" spin={loading} />}
								circle
								size="md"
								color="cyan"
								disabled={loading}
								onClick={reloadHandler}
								appearance="primary" />
						</ButtonToolbar>
					</div>
				</FlexboxGrid.Item>
			</FlexboxGrid>
			<Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Stack justifyContent="flex-start" alignItems="flex-start" spacing="6px" >
                <Filter
                    rawData={gitopsData}
                    setFilteredData={setFilteredData}
                    setCompareEnv={setCompareEnv}
                />
                <div style={{ flexGrow: 1 }} >
                    {(compareEnv === false) ? <>
                        {Object.values(gitGroups).map((item, index) =>
				            <div key={`grp-${item}-${index}`}>
					            {((isEmpty(filteredData) === false) && (filteredData.groups.findIndex(x => x.name === item) !== -1)) && <Group
						            name={filteredData.groups[filteredData.groups.findIndex(x => x.name === item)].name}
						            readOnly={props.readOnly}
						            status={filteredData.groups[filteredData.groups.findIndex(x => x.name === item)].status}
						            envs={filteredData.groups[filteredData.groups.findIndex(x => x.name === item)].environments}
						            rawData={filteredData}
					            />}
				            </div>
			            )} </> : <>
                            {((isEmpty(filteredData) === false) && (filteredData.groups.length !== 0)) && <Group
						        name={filteredData.groups[0].name}
						        key={"grp-compare-envs"}
						        readOnly={props.readOnly}
					            status={filteredData.groups[0].status}
					            envs={filteredData.groups[0].environments}
					            rawData={filteredData}
					        />}
                        </>
                    }
                </div>
            </Stack>
			{ (openRefresh === true) && <>
		        <AppDrawer
				name="Refresh"
			    operation="Refresh Apps"
				open={openRefresh}
			    onClose={() => setOpenRefresh(false)}
				rawData={filteredData}
		        />
			</>}
            { (openScale === true) && <>
				<AppDrawer
			        name="Scale"
					operation="Scale Apps"
			        open={openScale}
					onClose={() => setOpenScale(false)}
			        rawData={filteredData}
				/>
	        </>}
	    </>);
    }
};

export default Core;