import React from 'react';
import "rsuite/dist/rsuite.min.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./App.css"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Dashboard from './pages/dashboard';
import Database from './pages/database';
import Jobs from './pages/jobs';
import Deployment from './pages/deployment';
import Protect from './components/Protect';

function App() {
    const [useDarkTheme, setUseDarkTheme] = React.useState(false)

    React.useEffect(() => {
        const htmlElement = document.documentElement;
        if (htmlElement) {
            htmlElement.classList.remove('rs-theme-light', 'rs-theme-dark')
            htmlElement.classList.add(`rs-theme-${(useDarkTheme === false) ? 'light' : 'dark'}`)
        }
    }, [useDarkTheme])
  
    return (
        <div className={`ap rs-theme-${(useDarkTheme === false) ? 'light' : 'dark'}`}>
            <Router>
                <Routes>
                    <Route path='/' exact element={<Home />} />
                    <Route path='/dashboard' element={<Protect><Dashboard key="dashboard"  useDarkTheme={useDarkTheme} changeTheme={setUseDarkTheme} /></Protect>} />
                    <Route path='/database' element={<Protect><Database key="database"/></Protect>} />
                    <Route path='/jobs/manual/pending' element={<Protect><Jobs key="jobs" type="manual" subtype="pending"/></Protect>} />
                    <Route path='/jobs/manual/running' element={<Protect><Jobs key="jobs" type="manual" subtype="running"/></Protect>} />
                    <Route path='/jobs/manual/completed' element={<Protect><Jobs key="jobs" type="manual" subtype="completed"/></Protect>} />
                    <Route path='/jobs/scheduled' element={<Protect><Jobs key="jobs" type="scheduled"/></Protect>} />
                    <Route path='/jobs/approval' element={<Protect><Jobs key="jobs" type="approval"/></Protect>} />
                    <Route path='/deployment/core' element={<Protect><Deployment key="deployment" type='core' /></Protect>} />
                    <Route path='/deployment/mobile' element={<Protect><Deployment key="deployment" type='mobile' /></Protect>} />
                    <Route path='/deployment/terraform' element={<Protect><Deployment key="deployment" type='terraform' /></Protect>} />
                    <Route path="*" element={<Protect><h3>Page not found: 404!</h3></Protect>} />
                </Routes>
            </Router>
            <ToastContainer
                limit={3}
            />
        </div>
    );
}

export default App;