import React, { useState, useEffect } from "react";
import { Input,Panel, Form, Button, Message,Divider} from "rsuite";
import { toast } from 'react-toastify';
import { formCfg } from "../../../../FormGroup/config";
import { playFetch } from "../../../../../lib/playfetch";


const Secret = ({...props}) => {
    const [secretValue, setSecretValue] = useState('PLACEHOLDER');
    const [secretName, setSecretName] = React.useState("");
    const [formError, setFormError] = useState({});
    const [loadResult, setLoadResult] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true)
    
    const handleFormCheck = (formError) => {
        setFormError(formError);
    };

    const secretValueChangeHandler = (value) => {
        setSecretValue(value.trim());
    };
   
    const secretNameChangeHandler = (value) => {
        setSecretName(value.trim().toUpperCase());
    };

    const promoteSubmitHandler = (value, event) => {
        setLoadResult(true)
        const formData = new FormData();
        
        /* Append gcp_secrets_values */
        formData.append("gcp_secret_value", secretValue);
        
        
         /* Append gcp_secrets_names */
        formData.append("gcp_secret_name", secretName);

        playFetch(`generic/secrets/update_version/${props.group}/${props.env}`, "POST", formData)
            .then(element => {
                    if (element.status >= 400) {
                        toast.error(`${element.reason} - ${element.msg}`)
                    } else {
                        const msg = (<>
                                <p>
                                {element.id_type}:&nbsp;
                                    <a href={element.href} rel="noreferrer" target="_blank">{element.id}</a>
                                </p>
                            </>);
                        toast.success(msg)
                    }
                setLoadResult(false)
                //props.onClose()
            });
    }

    useEffect(() => {
        setSubmitDisabled(true)
        setLoadResult(false)
        if ((secretName.length > 0) &&
            (secretValue.length > 0)) {
                setSubmitDisabled(false)
        }
    }, [secretValue,secretName]);

    const isSubmitDisabled = ((Object.keys(formError).length > 0) || (submitDisabled));

    return (
        <>
            <Panel
                bordered
            >   
                {<Message
                    showIcon
                    type="info"
                    style={{
                        "--rs-message-info-icon": "#0000FF",
                        "--rs-message-info-text": "#0000FF",
                        "--rs-message-info-bg": "White",
                        marginTop: "5px"
                    }}
                >
                    <b>
                        {'Please be aware that by default all previous versions of \
                                newly modified secret will be disabled.'}
                    </b>
                </Message>}
                <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />             
                <Form onCheck={handleFormCheck} >
                    <Form.Group controlId="secretName" >
                        <Form.ControlLabel style={{ fontWeight: 'bold' }} >Secret Name:</Form.ControlLabel>
                        <Form.Control
                            name="secretName"
                            value={secretName}
                            accepter={Input}
                            onChange={secretNameChangeHandler}
                            placeholder="Enter existing secret name..."                          
                            style={{ width: formCfg.WIDTH }} />
                    </Form.Group>
                    <Form.Group controlId="secretValue" >
                        <Form.ControlLabel style={{ fontWeight: 'bold' }} >Secret Value:</Form.ControlLabel>
                        <Form.Control
                            name="secretValue"
                            value={secretValue}
                            accepter={Input}
                            onChange={secretValueChangeHandler}
                            placeholder="If not set default value is PLACEHOLDER"                          
                            style={{ width: formCfg.WIDTH }} />
                    </Form.Group>
                    <Button
                        appearance="primary"
                        disabled={isSubmitDisabled}
                        loading={loadResult}
                        onClick={promoteSubmitHandler}
                    >
                        Modify Secret version
                    </Button>
                </Form>
            </Panel>
        </>
    );
};

export default Secret;
