import React, { useState, useEffect } from "react";
import { Panel, Form, MultiCascader, Tag, Divider, Button } from "rsuite";
import { toast } from 'react-toastify';
import { find } from 'lodash';
import { formCfg } from "../../../../../FormGroup/config";
import { playFetch } from "../../../../../../lib/playfetch";
import EnvPicker from "../../../../../FormGroup/EnvPicker";

const Sha = ({ ...props }) => {
    const [multiApps, setMultiApps] = useState([]);
    const [toEnvs, setToEnvs] = useState([]);
    const [selectApps, setSelectApps] = useState([]);
    const [loadResult, setLoadResult] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);

    const promoteSubmitHandler = () => {
        setLoadResult(true)
        const formData = new FormData();

        /* Append promote_from_env */
        formData.append("promote_from_env", JSON.stringify({"group": props.group, "env": props.env}))

        /* Append commit sha details */
        const shaData = selectApps.map(app => {
            const foundApp = find(props.apps, el => ((el.name === app) || (find(el.modules, ch => (ch.name === app)))));
            if ((foundApp.name === app) && (foundApp.sha === "multiple") && (foundApp.hasOwnProperty('modules') === true)) {
                /* Push individual apps commit sha's listed under modules */
                const allModules = foundApp.modules.map(module => {
                    return {
                        name: module.name,
                        sha: module.sha
                    }
                });
                return allModules;
            } else if (foundApp.name === app) {
                /* Push app sha */
                return {
                    name: foundApp.name,
                    sha: foundApp.sha
                }
            } else {
                /* Push individual module sha */
                const foundModule = find(foundApp.modules, el => (el.name === app));
                return {
                    name: foundModule.name,
                    sha: foundModule.sha
                }
            }
        }).flat();

        for (let idx = 0; idx < shaData.length; idx++ ) {
            formData.append("commit_shas", JSON.stringify({"app": shaData[idx].name, "sha": shaData[idx].sha}))
        }

        /* Promote flag to signify that whole env is promoted */
        let promote_all_apps = false
        if (selectApps.length === shaData.length) {
            const appTypes = props.apps.map(x => {
                                if (x.name.startsWith("sweeps-")) {
                                    return undefined
                                } else {
                                    return x.name
                                }
                             }).filter(Boolean);
            promote_all_apps = appTypes.every(app => selectApps.includes(app))
        }
        formData.append("promote_all_apps", promote_all_apps);

        /* Append promote_to_envs */
        for (let idx = 0; idx < toEnvs.length; idx++) {
            if (toEnvs[idx].includes("/")) {
                formData.append("promote_to_envs", JSON.stringify({"group": toEnvs[idx].split("/").shift(), "env": toEnvs[idx].split("/").pop()}))
            } else {
                for (let grp_idx = 0; grp_idx < props.rawData.groups.length; grp_idx++) {
                    for (let env_idx = 0; env_idx < props.rawData.groups[grp_idx].environments.length; env_idx++) {
                        if (props.rawData.groups[grp_idx].environments[env_idx].grp_name === toEnvs[idx]) {
                            if (props.rawData.groups[grp_idx].environments[env_idx].name !== props.env) {
                                formData.append("promote_to_envs", JSON.stringify({"group": toEnvs[idx], "env": props.rawData.groups[grp_idx].environments[env_idx].name}))
                            }
                        }
                    }
                }
            }
        }

        playFetch(`deployments/core/sha/promote`, "POST", formData)
			.then(data => {
                data.forEach(response => {
                    response.status.forEach(element => {
                        if (element.status >= 400) {
                            toast.error(element.reason)
                        } else {
                            const msg = (<>
                                    <p>
                                        {element.id_type}:&nbsp;
                                        <a href={element.href} rel="noreferrer" target="_blank">{element.id}</a>
                                    </p>
                                </>);
                            toast.success(msg)
                        }
                    });
                });
                setLoadResult(false)
                props.onClose()
			});
    }

    const promoteEnvHandler = (value, event) => {
        setToEnvs(value)
    }

    const promoteAppHandler = (value, event) => {
        setSelectApps(value)
    }

    useEffect(() => {
        setLoadResult(false)
        setSubmitDisabled(true)
        const appData = props.apps.map(item => {
            const { name, sha } = item;
            return { label: name,
                     value: name,
                     sha: sha,
                     children: (item.hasOwnProperty('modules') === false) ? undefined : item.modules.map(module => {
                        const { name, sha } = module;
                        return { label: name,
                                 value: name,
                                 sha: sha
                        };
            })};
        });
        setMultiApps(appData)
        const appValuesOnly = props.apps.map(item => {
            return item.name
        })
        setSelectApps(appValuesOnly)
	}, [props.apps, props.env, props.group, props.rawData]);

    useEffect(() => {
        setSubmitDisabled(true)
        setLoadResult(false)
        if ((toEnvs.length > 0) &&
            (selectApps.length > 0)) {
                setSubmitDisabled(false)
        }
    }, [toEnvs, selectApps]);

    return (
        <>
            <Panel
                bordered
                header={`${props.group}/${props.env} commit shas`}
            >
                {Object.values(multiApps).map((item, index) => <React.Fragment key={`key-${item.label}-${index}`}>
                    {(item.sha === "multiple") ?
                        Object.values(item.children).map(child => <>
                            <Tag color="yellow" key={`tag-key-${child.label}-${child.sha}`} style={{ marginBottom: "10px" }}>
                                <div style={{ color: "#4C4E52" }}>{child.label}:&nbsp;{child.sha}</div>
                            </Tag>
                        </>) :
                        <Tag color="yellow" key={`tag-key-${item.label}-${item.sha}`}>
                            <div style={{ color: "#4C4E52" }}>{item.label}:&nbsp;{item.sha}</div>
                        </Tag>
                    }
                </React.Fragment>
                )}
                <Divider />
                <Form>
                    <Form.Group controlId="promote-env" >
                        <Form.ControlLabel style={{ fontWeight: 'bold' }} >Promote to environments:</Form.ControlLabel>
                        <Form.Control
							name="promote-env"
							key="promote-env"
							value={toEnvs}
							type={['core/ALL', 'wincore/ALL']}
                            cascade={true}
							multiPick={true}
                            rawValue={true}
                            isGitopsStyle={true}
							accepter={EnvPicker}
							onChange={promoteEnvHandler}
                            onClean={promoteEnvHandler}
							style={{ width: formCfg.WIDTH }}
                        />
                    </Form.Group>
                    <Form.Group controlId="promote-apps" >
                        <Form.ControlLabel style={{ fontWeight: 'bold' }} >Promote Apps:</Form.ControlLabel>
                        <Form.Control
                            name="promote-apps"
                            key="promote-apps"
                            value={selectApps}
                            data={multiApps}
                            menuWidth={formCfg.WIDTH / 2}
                            accepter={MultiCascader}
                            onChange={promoteAppHandler}
                            style={{ width: formCfg.WIDTH }} />
                    </Form.Group>
                    <Button
                        appearance="primary"
                        disabled={submitDisabled}
                        loading={loadResult}
                        onClick={() => promoteSubmitHandler()}
                    >
                        Promote
                    </Button>
                </Form>
            </Panel>
        </>
    );
};

export default Sha;
