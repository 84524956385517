import React from "react";
import { toast } from 'react-toastify';
import Confirm from "../Common/confirm";
import { playFetch } from "../../../lib/playfetch";

const Delete = ({...props}) => {    
    const deleteRecord = () => {
        Promise.all(props.records.children.map(record =>
            playFetch(`${props.apiUrl}/delete/${record.name}/${record.id}`)))
                .then(allData =>
                    allData.forEach(data => {
                        if(data.status === 200) {
                            toast.success(data.msg);
                        } else {
                            toast.error(data.detail.msg);
                        }
                    })
                ).finally(() => {
                    props.refetchData()
                });
    }

    return (
        <>
            <b>Are you sure to delete ?</b>
            <Confirm onConfirm={deleteRecord} onCancel={props.refetchData} />
        </>
    );
};
export default Delete;