import React from "react";
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { GoogleLogin } from "@react-oauth/google";
import { get } from "lodash";
import { playFetch } from "../../lib/playfetch";
import { appCfg } from "../../config/app";
import { useNavigate } from "react-router-dom";

const Login = () => {
	const navigate = useNavigate();

	const onSuccess = (gResponse) => {
		if (gResponse.credential) {
			const formData = new FormData();
			formData.append("token", gResponse.credential)
			playFetch("generic/auth/login", "POST", formData)
				.then(data => {
                    if (get(data, 'detail', undefined) === undefined) {
					    const cookieData = CryptoJS.AES.encrypt(
						    JSON.stringify(data),
						    appCfg.ENCRYPT_PASS
					    ).toString();
					    const expires_in_ms = new Date(new Date().getTime() + data['expires_in_minutes'] * 60 * 1000)
					    Cookies.set('access_token', cookieData, {expires: expires_in_ms})
                    } else {
                        toast.error(data.detail.msg)
                    }
                    navigate("/dashboard",  { replace: true })
				});
		}
	};

	const onFailure = (res) => {
		console.log("[Login Failed] res:", res);
		Cookies.remove('access_token')
	};

	return (
		<div>
			<GoogleLogin
				hosted_domain="betcloud.com.au"
                theme="outline"
                shape="pill"
				onSuccess={onSuccess}
				onFailure={onFailure}
				cookiePolicy={'single_host_origin'} />
		</div>
	);
};

export default Login;
