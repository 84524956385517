import React, { useState } from "react";
import { Modal, List } from 'rsuite';
import { toast } from 'react-toastify';
import { uniq } from "lodash";
import { find } from "lodash";
import { playFetch } from "../../lib/playfetch";

const ApprovedJobs = ({...props}) => {
    const [approvedJobsData, setApprovedJobsData] = useState([]);
    const [rawJobData, setRawJobData] = useState([]);
    
    const fetchSelectApprovedJobsData = () => {
        const apiUrl = `generic/jobs/manual/fetch/approved`

        playFetch(apiUrl)
            .then(data => {
                if (data.length === 0) {
                    props.closeMe()
                    toast.info("No approved jobs to prefill");
                } else {
                    const approvalIds = uniq(data.map(item => {
                        return item.approval_id;
                    }))

                    const selectData = approvalIds.map(item => {
                        const obj = find(data, {"approval_id": item})
                        const label = `${obj.remarks} -- ${obj.submitted_by.split("@")[0]} -- ${obj.approved_by.split("@")[0]}`
                        return {
                            label: label,
                            value: item
                        }
                    })

                    setApprovedJobsData(selectData);
                    setRawJobData(data);
                }
            });
    }

    const selectJobHandler = (approvalId) => {
        const envName = rawJobData.map(item => {
            if (item.approval_id === approvalId) {
                return item.environment;
            }
            return undefined
        }).filter(Boolean)

        const query = find(rawJobData, {"approval_id": approvalId}).query
        const remarks = find(rawJobData, {"approval_id": approvalId}).remarks

        props.setEnvName(envName)
        props.setApprovalId(approvalId)
        props.setQuery(query)
        props.setRemarks(remarks)
        props.closeMe()
    }

    return (
        <>
            <Modal
                backdrop={true}
                keyboard={false}
                open={props.openModal}
                onEntering={fetchSelectApprovedJobsData}
                onClose={() => props.closeMe()}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                    <Modal.Header closeButton={true}>
                        <Modal.Title style={{ color: "green", fontWeight: "bold", fontSize: "1.5em" }}>
                            Approved Requests
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <List bordered hover>
                            {approvedJobsData.map((item, index) => <div key={`list-approval-${index}`}
                                onClick={() => selectJobHandler(item.value)}
                                style={{
                                    padding: "4px",
                                    flex: 1,
					                cursor: "pointer",
					                "&:hover": {
		    			                cursor: "pointer"
			    	                }
				                }}
                            >
                                <List.Item>
                                    <b>{item.label}</b>
                                </List.Item>
                            </div>)}
                        </List>
                    </Modal.Body>
            </Modal>
        </>
    );
};
export default ApprovedJobs;
