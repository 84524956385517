import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import omit from 'lodash/omit';
import Confirm from "../../Common/confirm";
import { playFetch } from "../../../../lib/playfetch";

const Apply = ({...props}) => {
    const [loading, setLoading] = useState(false);

    useEffect( () => {
        async function autoApply() {
            await operationHandler();
        }
        autoApply();
    });
    
    const applyRecord = async (record) => {
        const formData = new FormData();
        formData.append("record", JSON.stringify(omit(record, ["id", "children", "venue_name", "race_number", "match_name", "competition_name"])));
        await Promise.all(record.children.map(env =>
            playFetch(`${props.apiUrl}/apply/${env.name.split('-')[0]}`, "POST", formData)))
                .then(allData =>
                    allData.forEach(data => {
                        if(data.status === 200) {
                            toast.success(data.msg);
                        } else {
                            toast.error(data.detail.msg);
                        }
                    })
                ).finally(() => {
                    props.applyRecord(props.record.id)
                });
        return
    }

    const operationHandler = async () => {
        if (loading === true) {
            return;
        }
        setLoading(true)
        await applyRecord(props.record)
        setLoading(false)
    };

    const cancelHandler = async () => {
        await props.cancelHandler(props.record)
        props.refetchData()
    };

    return (
        <>
            <div className="add-records-col" style={{ display: "block" }}>
                {(loading === false) && <b>Are you sure to apply ?</b>}
                <Confirm loading={loading} onConfirm={operationHandler} onCancel={cancelHandler} />
            </div>
        </>
    );
};
export default Apply;
